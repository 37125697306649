import $ from 'jquery'

export default function login() {
  const $kikanInput = $('#kikan')
  const $shitenInput = $('#shiten')
  const $submitButton = $('.login-form_submitButton')
  const landingPage = 'landingPage'
  const userData = 'userData'
  const storageData = JSON.parse(localStorage.getItem(userData))
  const formItem = '.login-form_item'
  const $userName = $('.l-header_userName')
  const $logoutButton = $('.l-header_logoutButton')
  const CSS_CLASSNAME = {
    error: 'login-form_item-error',
  }
  let hostname

  // submitボタンを無効にする
  $submitButton.prop('disabled', true)

  // 入力値のチェック
  const errorCheck = () => {
    if ($kikanInput.val() !== '') {
      // 金融機関コードの入力値が4文字
      if ($kikanInput.val().length === 4) {
        if ($shitenInput.val().length === 3) {
          $submitButton.prop('disabled', false)
        }
      } else {
        $submitButton.prop('disabled', true)
      }
    }
    if ($shitenInput.val() !== '') {
      // 支店コードの入力値が3文字
      if ($shitenInput.val().length === 3) {
        if ($kikanInput.val().length === 4) {
          $submitButton.prop('disabled', false)
        }
      } else {
        $submitButton.prop('disabled', true)
      }
    }
  }
  $('#kikan, #shiten').on('input', function () {
    errorCheck()
  })

  // エンドポイント
  if (process.env.NODE_ENV === 'development') {
    hostname = 'nkgr:e2SZTJuK@' + process.env.API_BASE_URL
  } else {
    hostname = process.env.API_BASE_URL
    if (location.hostname !== process.env.API_BASE_URL) {
      hostname = location.hostname
    }
  }
  const endpoint = 'https://' + hostname + '/wp-json/wp/v1/banks'

  // jsonの値を取得して配列に格納
  const bankList = []
  $.ajax({
    type: 'GET',
    url: endpoint,
    dataType: 'json',
  }).then(function (json) {
    for (let i = 0; i < json.length; i++) {
      bankList.push({
        id: json[i].id,
        name: json[i].name,
        code: json[i].code,
      })
    }
  })

  // データの保存
  $submitButton.on('click', function (e) {
    if ($kikanInput.val().length === 4 && $shitenInput.val().length === 3) {
      const bank = bankList.find(item => item.code === $kikanInput.val())
      if (bank) {
        const bankData = {
          kikanCode: bank.code,
          kikanName: bank.name,
          shitenCode: $shitenInput.val(),
        }
        localStorage.setItem(userData, JSON.stringify(bankData))
        if (JSON.parse(localStorage.getItem(landingPage))) {
          window.location.href = JSON.parse(localStorage.getItem(landingPage))
        } else {
          window.location.href = '/'
        }
      } else {
        $kikanInput.parents(formItem).addClass(CSS_CLASSNAME.error)
      }
    } else if ($kikanInput.val().length !== 4 && $shitenInput.val().length === 3) {
      $kikanInput.parents(formItem).addClass(CSS_CLASSNAME.error)
      $shitenInput.parents(formItem).removeClass(CSS_CLASSNAME.error)
    } else if ($kikanInput.val().length === 4 && $shitenInput.val().length !== 3) {
      $kikanInput.parents(formItem).removeClass(CSS_CLASSNAME.error)
      $shitenInput.parents(formItem).addClass(CSS_CLASSNAME.error)
    } else {
      $kikanInput.parents(formItem).addClass(CSS_CLASSNAME.error)
      $shitenInput.parents(formItem).addClass(CSS_CLASSNAME.error)
    }
    e.preventDefault()
  })

  // リダイレクト処理
  if (location.pathname === '/login/') {
    if (storageData !== null) {
      window.location.href = '/'
    }
  } else {
    if (storageData === null) {
      localStorage.setItem(landingPage, JSON.stringify(location.pathname))
      window.location.href = '/login/'
    }
  }

  // 保存データを取得して金融機関名表示
  if (storageData !== null) {
    $userName.text(storageData.kikanName)
  }

  // ログアウト
  $logoutButton.on('click', function () {
    localStorage.removeItem(userData)
    localStorage.removeItem(landingPage)
  })
}
