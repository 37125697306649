import $ from 'jquery'

export default function headerMenu() {
  const $header = $('.l-header')
  const $classOpen = $('.js-menuButton, .js-menuArea')
  const $menuButton = $('.js-menuButton')
  const CSS_CLASSNAME = {
    open: 'is-open',
    fixed: 'is-fixed',
    visible: 'is-visible',
    hide: 'is-hide',
  }
  let state = false
  let scrollpos

  $menuButton.on('click', function (e) {
    if ($(this).hasClass(CSS_CLASSNAME.open)) {
      $classOpen.removeClass(CSS_CLASSNAME.open)
      $('body').removeAttr('style')
    } else {
      $classOpen.addClass(CSS_CLASSNAME.open)
    }

    if (state === false) {
      scrollpos = $(window).scrollTop()
      $('body').addClass(CSS_CLASSNAME.fixed).css({ top: -scrollpos })
      state = true
    } else {
      $('body').removeClass(CSS_CLASSNAME.fixed).css({ top: 0 })
      $header.addClass(CSS_CLASSNAME.visible)
      window.scrollTo(0, scrollpos)
      setTimeout(function () {
        $header.removeClass(CSS_CLASSNAME.visible)
        $header.removeClass(CSS_CLASSNAME.hide)
      }, 100)
      state = false
    }
    e.preventDefault()
  })
}
