import $ from 'jquery'

export default function headerFade() {
  const $header = $('.l-header')
  const elementTop = 80
  const CSS_CLASSNAME = {
    show: 'is-show',
    hide: 'is-hide',
  }
  let beforePos = 0
  let scroll

  function scrollAnime() {
    scroll = $(window).scrollTop()

    if (scroll === beforePos) {
      // IE11対策で処理を入れない
    } else if (elementTop > scroll || scroll - beforePos < 0) {
      // ヘッダー表示
      $header.addClass(CSS_CLASSNAME.show)
      $header.removeClass(CSS_CLASSNAME.hide)
    } else {
      // ヘッダー非表示
      $header.addClass(CSS_CLASSNAME.hide)
      $header.removeClass(CSS_CLASSNAME.show)
    }
    beforePos = scroll // 現在のスクロール値を比較用のbeforePosに格納
  }

  $(window).on('load scroll', function () {
    scrollAnime()
  })
}
