import $ from 'jquery'

export default function top() {
  const $slider = $('.js-slider-top')
  const $pager = $('.top-hero_sliderPager')
  const pauseButton = '.js-pause'
  const playButton = '.js-play'
  const storageData = JSON.parse(localStorage.getItem('userData'))
  const $newPostSlider = $('.js-slider-new')
  const CSS_CLASSNAME = {
    active: 'is-active',
    paused: 'is-paused',
  }
  let hostname

  // レコメンド記事
  if ($slider.length >= 1) {
    function sliderInit() {
      $slider.slick({
        arrows: true,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        dots: true,
        appendDots: $pager,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        cssEase: 'ease-out',
        touchThreshold: 10,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
      })
    }
    sliderInit()

    // スライダーの停止・開始
    $(pauseButton).on('click', function () {
      $slider.slick('slickPause')
      $(this).addClass(CSS_CLASSNAME.active)
      $(this).siblings(playButton).removeClass(CSS_CLASSNAME.active)
      $('.top-hero_sliderPager .slick-dots li button').addClass(CSS_CLASSNAME.paused)
    })
    $(playButton).on('click', function () {
      $slider.slick('slickPlay')
      $(this).addClass(CSS_CLASSNAME.active)
      $(this).siblings(pauseButton).removeClass(CSS_CLASSNAME.active)
      $('.top-hero_sliderPager .slick-dots li button').removeClass(CSS_CLASSNAME.paused)
    })

    if (storageData !== null) {
      // エンドポイント
      if (process.env.NODE_ENV === 'development') {
        hostname = 'nkgr:e2SZTJuK@' + process.env.API_BASE_URL
      } else {
        hostname = process.env.API_BASE_URL
        if (location.hostname !== process.env.API_BASE_URL) {
          hostname = location.hostname
        }
      }
      const endpoint =
        'https://' + hostname + '/wp-json/wp/v1/recommendation?code=' + storageData.kikanCode

      // jsonの値を取得して配列に格納
      const post = []
      $.ajax({
        type: 'GET',
        url: endpoint,
        dataType: 'json',
      }).then(function (json) {
        for (let i = 0; i < json.length; i++) {
          post.push({
            title: json[i].title,
            thumbnail: json[i].thumbnail,
            industry: json[i].industry,
            url: json[i].url,
            content: json[i].content,
          })
        }

        $slider.empty() // スライダー内のHTMLを削除

        let html = ''
        $.each(post, function (index, value) {
          const link = value.url
          const title = value.title
          const thumbnail = value.thumbnail
          const industryName = value.industry.name
          const industrySlug = value.industry.slug
          const content = value.content

          html += '<div class="top-hero_card">'
          html += '<a href="' + link + '">'
          html += '<img src="' + thumbnail + '">'
          html += '<div class="top-hero_cardTextBox">'
          html += '<div class="c-label c-label-' + industrySlug + '">' + industryName + '</div>'
          html += '<h2 class="top-hero_cardTitle">' + title + '</h2>'
          html += '<p class="top-hero_cardText">' + content + '</p>'
          html += '</div>'
          html += '</a>'
          html += '</div>'
        })

        $slider.append(html) // スライダー内に生成したHTMLを追加

        // スライダーを初期化
        $slider.slick('unslick')
        sliderInit()
      })
    }
  }

  // 新着記事
  if ($newPostSlider.length >= 1) {
    const settings = {
      infinite: false,
      arrows: true,
      slidesToShow: 4,
      variableWidth: true,
      dots: false,
      autoplay: false,
      speed: 750,
      cssEase: 'ease-out',
      touchThreshold: 10,
      responsive: [
        {
          breakpoint: 1140, // 870〜1139px以下のサイズに適用
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 870, // 560〜869px以下のサイズに適用
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 560, // 〜559px以下のサイズに適用
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    $newPostSlider.slick(settings)
  }
}
