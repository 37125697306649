import './polyfill'
import 'what-input'
import '../../../node_modules/slick-carousel/slick/slick.min.js'

import smoothScroll from '@namespace/common/smoothScroll'
import headerMenu from '@namespace/header/menu'
import headerFade from '@namespace/header/fade'
import headerToggle from '@namespace/header/toggle'
import headerCurrent from '@namespace/header/current'
import top from '@namespace/top/top'
import login from '@namespace/login/login'

smoothScroll()
headerMenu()
headerFade()
headerToggle()
headerCurrent()
top()
login()
