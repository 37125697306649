import $ from 'jquery'

export default function smoothScroll() {
  const $link = $('a[href^="#"]:not(.js-scrollNone)')
  const headerHeight = 0 // ヘッダーが固定の場合はヘッダーの高さを追加
  const scrollDuration = 500
  let isScrolling = false

  $link.on('click', function (e) {
    const href = $(this).attr('href')
    const target = $(href === '#' || href === '' ? 'html' : href)
    const position = target.offset().top - headerHeight
    if (!isScrolling) {
      isScrolling = true
      $('html, body').animate(
        {
          scrollTop: position,
        },
        scrollDuration,
        function () {
          isScrolling = false
        },
      )
    }
    e.preventDefault()
  })
}
