import $ from 'jquery'

export default function headerToggle() {
  const $toggleWrap = $('.l-header_menuAreaInner')
  const $toggleArea = $('.js-toggleArea')
  const toggleButton = '.js-toggleButton'
  const toggleClose = '.js-toggleClose'
  const CSS_CLASSNAME = {
    open: 'is-open',
  }
  let buttonData

  $(document).on('click', toggleButton, function (e) {
    // クリックしたボタンのdata属性を取得
    buttonData = $(this).data('id')
    if ($(this).hasClass(CSS_CLASSNAME.open)) {
      $('#' + buttonData)
        .stop()
        .slideUp(350)
      $(this).removeClass(CSS_CLASSNAME.open)
      $toggleWrap.removeClass(CSS_CLASSNAME.open)
    } else {
      $toggleArea.hide()
      $(toggleButton).removeClass(CSS_CLASSNAME.open)
      $('#' + buttonData)
        .stop()
        .slideDown(350)
      $(this).addClass(CSS_CLASSNAME.open)
      $toggleWrap.addClass(CSS_CLASSNAME.open)
    }
    e.preventDefault()
  })

  $(document).on('click', toggleClose, function (e) {
    $(this).parent().stop().slideUp(350)
    $(toggleButton).removeClass(CSS_CLASSNAME.open)
    $toggleWrap.removeClass(CSS_CLASSNAME.open)
    e.preventDefault()
  })
}
