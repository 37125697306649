import $ from 'jquery'

export default function headerCurrent() {
  const $targetNav = $('.l-header_navList > li a')
  const $targetIssue = $('.l-header_issueList > li a')
  const protocol = location.protocol
  const host = location.hostname
  const path = location.pathname
  const url = protocol + '//' + host + '/' + path.split('/')[1]
  const urlChild =
    protocol + '//' + host + '/' + path.split('/')[1] + '/' + path.split('/')[2] + '/'
  const CSS_CLASSNAME = {
    active: 'is-active',
  }

  $targetNav.each(function () {
    if ($(this).attr('href') === url) {
      $(this).addClass(CSS_CLASSNAME.active)
    } else if ($(this).attr('href') === urlChild) {
      $(this).addClass(CSS_CLASSNAME.active)
    }
  })

  $targetIssue.each(function () {
    if ($(this).attr('href') === urlChild) {
      $(this)
        .parents('.l-header_menuAreaInner')
        .siblings('.l-header_nav')
        .find('.js-toggleButton')
        .addClass(CSS_CLASSNAME.active)
    }
  })
}
